import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Header from '../components/Header';
import '../styles/index.scss';

// markup
const IndexPage = () => {
  return (
    <>
      <title>Home | Curious Hedgehog</title>
      <Header />
      <main>
        <div className="container">
          <div className="grid">
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_01.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">Duck Duck Goose On The Lose</div>
                  <div className="card__summary">
                    Lorem ipsum de la cote baracko sitovila restrop treicer
                    oligraca Lorem ipsum.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_02.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">
                    My Dog Feeding Time Nightmare
                  </div>
                  <div className="card__summary">
                    Lorem ipsum de la cote baracko sitovila restrop treicer
                    oligraca Lorem ipsum.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_03.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">Go Raptors</div>
                  <div className="card__summary">
                    Lorem ipsum de la cote baracko sitovila restrop treicer
                    oligraca Lorem ipsum.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_04.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">
                    My Dog Feeding Time Nightmare
                  </div>
                  <div className="card__summary">
                    Ipsum de la cote baracko sitovila restrop treicer oligraca
                    chilerico.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_05.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">
                    My Dog Feeding Time Nightmare
                  </div>
                  <div className="card__summary">
                    Sitovila restrop treicer oligraca Lorem ipsum.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_01.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">
                    My Dog Feeding Time Nightmare
                  </div>
                  <div className="card__summary">
                    Lorem ipsum de la cote baracko sitovila restrop treicer
                    oligraca Lorem ipsum.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_02.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">
                    My Dog Feeding Time Nightmare
                  </div>
                  <div className="card__summary">
                    Lorem ipsum de la cote baracko sitovila restrop treicer
                    oligraca Lorem ipsum.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_03.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">
                    My Dog Feeding Time Nightmare
                  </div>
                  <div className="card__summary">
                    Lorem ipsum de la cote baracko sitovila restrop treicer
                    oligraca Lorem ipsum.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_04.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">
                    My Dog Feeding Time Nightmare
                  </div>
                  <div className="card__summary">
                    Ipsum de la cote baracko sitovila restrop treicer oligraca
                    chilerico.
                  </div>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="card">
                <div className="card__image">
                  <StaticImage
                    src="../images/place_05.jpg"
                    alt="placeholder"
                    placeholder="blurred"
                    width={960}
                  />
                </div>
                <div className="card__inner">
                  <div className="card__title">
                    My Dog Feeding Time Nightmare
                  </div>
                  <div className="card__summary">
                    Sitovila restrop treicer oligraca Lorem ipsum.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default IndexPage;
